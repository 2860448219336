import React from "react";

const bucket = "snakle_diffusion"
// const bucket = "snakle_temp"

const apiURL = `https://api-4jc4qexdra-uc.a.run.app/action?pass=oliver3&op=delete&bucket=${bucket}&img=`
const imagesURL = `https://api-4jc4qexdra-uc.a.run.app/images?bucket=${bucket}`

export default class Slideshow extends React.Component {
    constructor(props) {
        super(props)

        window.addEventListener('keydown', (event) => {
            switch (event.code) {
                case "ArrowDown":
                    this.delete()
                    break;
                case "ArrowLeft":
                    this.previous()
                    break;
                case "ArrowRight":
                    this.next()
                    break;
                default:
                    break
            }
        })
        this.state = {
            i: 0,
            history: [],
            images: [],
        }
    }

    componentDidMount() {
        const { history, images } = this.state
        if (images.length > 0) {
            return
        }
        
        fetch(imagesURL)
        .then((response) => response.json())
        .then((d) => {
            const j = Math.floor(Math.random() * d.length)
            history.push(j)
            this.setState({images: d})
        })
    }

    getNext() {
        const { images } = this.state
        return Math.floor(Math.random() * images.length)
    }

    delete() {
        return
        let { i, history, images } = this.state
        fetch(apiURL + images[history[i]])
        setTimeout(() => window.location.reload(), 200)
    }

    previous() {
        let { i } = this.state
        const n = Math.max(i - 1, 0)
        this.setState({
            i: n,
        })
    }

    next() {
        let { i, history } = this.state
        if (i < history.length - 1) {
            this.setState({
                i: i + 1,
            })
        }
        const n = this.getNext()
        history.push(n)
        this.setState({
            i: i + 1,
        })
    }

    render() {
        let { i, history, images } = this.state
        if (images.length === 0) {
            return undefined
        }

        return (
            <div style={{ margin: "auto" }}>
                <div style={{ display: "block", minHeight: "120px", width: "100%" }}>
                    <img key={i} src={`https://storage.googleapis.com/${bucket}/${images[history[i]]}`} alt={i} style={{maxWidth: "100%"}}/>
                    {/* <img key={i} src={"http://34.111.18.196/" + images[history[i]]} alt={i}/> */}
                </div>
                <div id="controls" style={{ diplay: "block" }}>
                    <button
                        disabled={true}
                        style={{ display: "inline-block", width: "100px", height: "50px" }}
                        onClick={() => {
                            fetch(apiURL + images[history[i]])
                            setTimeout(() => window.location.reload(), 200)
                        }}
                    >
                        delete
                    </button>
                    <button
                        style={{ display: "inline-block", width: "100px", height: "50px" }}
                        onClick={() => this.previous()}
                    >
                        previous
                    </button>
                    <button
                        style={{ display: "inline-block", width: "100px", height: "50px" }}
                        onClick={() => this.next()}
                    >
                        next
                    </button>
                </div>
            </div>
        )
    }
}
